import React, { Component } from "react";
import Seo from "../components/seo";
import Fade from "react-reveal/Fade";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ComingSoon from "../components/coming-soon";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appReady: false,
            comingSoon: false,
            language: "en",
        };
    }

    changeLanguage = language => {
        this.setState({ language });
    };

    setAppReady = () => {
        this.setState({ appReady: true });
    };

    loadApp = () => {
        setTimeout(this.setAppReady, 400);
    };

    componentDidMount() {
        this.loadApp();
    }

    render() {
        let clonedChildren = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, { language: this.state.language });
        });
        return (
            <>
                <Seo />
                {this.state.comingSoon ? <ComingSoon /> : null}
                {this.state.appReady && !this.state.comingSoon ? (
                    <div className="container-fluid pl-0 pr-0">
                        <Navbar
                            lang={this.state.language}
                            changeLanguage={this.changeLanguage}
                        />
                        {clonedChildren}
                        <Footer lang={this.state.language} />
                    </div>
                ) : null}
            </>
        );
    }
}

export default Page;
