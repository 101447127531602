import React, { Component } from "react";

import { FiLogIn } from "react-icons/fi";
import Translate from "./translation/translate";

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: null,
            email: "",
        };
    }

    subscribeToEvents = async () => {
        this.setState({ loading: true });
        await fetch(
            `/campaign-management/add-client?firstName=''&lastName=''&email=${this.state.email}`,
            {
                method: "POST",
            }
        );
        this.setState({ loading: null });
    };

    handleEmailChange = e => {
        let email = e.currentTarget.value;
        this.setState({ email });
    };

    render() {
        return (
            <div
                className="container-fluid bg-primary-dark"
                style={this.props.style}
            >
                <div className="section container text-light d-flex flex-column flex-md-row align-items-center">
                    <div className="col-sm-12 col-md-6">
                        <p className="display-5 text-underlined text-secondary">
                            <Translate
                                lang={this.props.lang}
                                path={this.props.titlePath}
                            />
                        </p>
                        <p className="text-light">
                            <Translate
                                lang={this.props.lang}
                                path={this.props.subtitlePath}
                            />
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                            <label>
                                <Translate
                                    lang={this.props.lang}
                                    path={["newsletter", "input", "label"]}
                                />
                            </label>
                            <input
                                placeholder={new Translate().getTranslation(
                                    this.props.lang,
                                    ["newsletter", "input", "placeholder"]
                                )}
                                type="email"
                                className="form-control"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                            />
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.subscribeToEvents}
                        >
                            {this.state.loading ? (
                                <div
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <>
                                    <FiLogIn />
                                    <span>
                                        <Translate
                                            lang={this.props.lang}
                                            path={[
                                                "newsletter",
                                                "input",
                                                "buttonText",
                                            ]}
                                        />
                                    </span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Newsletter;
