import React, { Component } from "react";
import logo from "../../static/logo-pink.svg";

import Translate from "./translation/translate";

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: "",
            menu: [
                {
                    title: "Find your space",
                    link: "/#contact-us",
                },
                {
                    title: "Listings",
                    link: "/#listings",
                },
                {
                    title: "Events",
                    link: "/events",
                },
            ],
        };
    }

    render() {
        let menuItems = this.state.menu.map((menuItem, index) => (
            <li
                key={`nav-item-${menuItem.title
                    .toLowerCase()
                    .replace(/\s/g, "-")}`}
                className={`nav-item ${
                    menuItem.title === this.state.active ? "active" : ""
                }`}
            >
                <a className="nav-link" href={menuItem.link}>
                    <Translate
                        lang={this.props.lang}
                        path={["navItems", index]}
                    />
                </a>
            </li>
        ));

        let oppositeLang = this.props.lang === "en" ? "es" : "en";
        return (
            <div className="container-fluid bg-white shadow-sm">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light pt-4 pb-4">
                        <a className="navbar-brand" href="/">
                            <img className="navbar-brand-logo" src={logo} />
                        </a>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarText"
                            aria-controls="navbarText"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarText"
                        >
                            <ul className="navbar-nav ml-auto">
                                <li
                                    className="nav-item"
                                    onClick={this.props.changeLanguage.bind(
                                        this,
                                        oppositeLang
                                    )}
                                >
                                    <span
                                        className="nav-link"
                                        style={{ cursor: "pointer" }}
                                    >
                                        {oppositeLang}
                                    </span>
                                </li>
                                {menuItems}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Navbar;
