import React, { Component } from "react";

const data = require("../../../static/translations.json");

class Translate extends Component {
    getTranslation = (language, path) => {
        let dataForLanguage = data[language];
        path.forEach(prop => {
            dataForLanguage = dataForLanguage[prop];
        });

        return dataForLanguage;
    };

    render() {
        return <>{this.getTranslation(this.props.lang, this.props.path)}</>;
    }
}

export default Translate;
