import React, { Component } from "react";
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import {
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoTwitter,
    IoLogoWhatsapp,
} from "react-icons/io";

import logo from "../../static/logo-pink.svg";
import Translate from "./translation/translate";
import memberLogo from "../../static/GLCC logo.svg";

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid bg-light">
                <div className="section container d-flex flex-column flex-md-row">
                    <div className="col-sm-12 col-md-3">
                        <img src={logo} className="img-fluid" style={{maxWidth: 148}}/>
                        <p className="text-muted mt-4">
                            <Translate
                                lang={this.props.lang}
                                path={["footer", "description"]}
                            />
                        </p>
                        <p className="small text-muted mt-4">
                            Copyright © {new Date().getFullYear()} JAM Universe,
                            Inc. dba JAM Real Estate Group
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-1"></div>
                    <div className="col-sm-12 col-md-2 ml-sm-0">
                        <p className="footer-menu-item text-underlined">
                            <Translate
                                lang={this.props.lang}
                                path={["footer", "menu", 0, "title"]}
                            />
                        </p>
                        <ul className="footer-menu-list list-unstyled mt-4">
                            <li>
                                <a
                                    className="text-dark text-decoration-none"
                                    href="/"
                                >
                                    <Translate
                                        lang={this.props.lang}
                                        path={[
                                            "footer",
                                            "menu",
                                            0,
                                            "menuItems",
                                            0,
                                        ]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-dark text-decoration-none"
                                    href="/#our-team"
                                >
                                    <Translate
                                        lang={this.props.lang}
                                        path={[
                                            "footer",
                                            "menu",
                                            0,
                                            "menuItems",
                                            1,
                                        ]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-dark text-decoration-none"
                                    href="/#contact-us"
                                >
                                    <Translate
                                        lang={this.props.lang}
                                        path={[
                                            "footer",
                                            "menu",
                                            0,
                                            "menuItems",
                                            2,
                                        ]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-dark text-decoration-none"
                                    href="/events"
                                >
                                    <Translate
                                        lang={this.props.lang}
                                        path={[
                                            "footer",
                                            "menu",
                                            0,
                                            "menuItems",
                                            3,
                                        ]}
                                    />
                                </a>
                            </li>
                        </ul>
                        <img src={memberLogo} className="img-fluid mt-4 mb-4" style={{maxWidth: 124}}/>
                    </div>
                    <div className="col-sm-12 col-md-2">
                        <p className="footer-menu-item text-underlined ">
                            <Translate
                                lang={this.props.lang}
                                path={["footer", "menu", 1, "title"]}
                            />
                        </p>
                        <ul className="footer-menu-list list-unstyled mt-4">
                            <li>
                                <a
                                    target="_blank"
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="https://chatwith.io/s/5f84c136a6b9e"
                                >
                                    <IoLogoWhatsapp />
                                    <span className="ml-2">Whatsapp</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="tel:1-305-306-7009"
                                >
                                    <FiPhoneCall />
                                    <span className="ml-2">(305) 306 7009</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="#"
                                >
                                    <FiMail />
                                    <span className="ml-2">
                                        <Translate
                                            lang={this.props.lang}
                                            path={[
                                                "footer",
                                                "menu",
                                                1,
                                                "menuItems",
                                                0,
                                            ]}
                                        />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-2">
                        <p className="footer-menu-item text-underlined">
                            Social
                        </p>
                        <ul className="footer-menu-list list-unstyled mt-4">
                            <li>
                                <a
                                    target="_blank"
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="https://www.facebook.com/jamregroup/"
                                >
                                    <IoLogoFacebook />
                                    <span className="ml-2">Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="https://www.instagram.com/jamregroup/"
                                >
                                    <IoLogoInstagram />
                                    <span className="ml-2">Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    className="text-dark text-decoration-none d-flex align-items-center"
                                    href="https://twitter.com/jamregroup"
                                >
                                    <IoLogoTwitter />
                                    <span className="ml-2">Twitter</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-2">
                        <p className="footer-menu-item text-underlined ">
                            <Translate
                                lang={this.props.lang}
                                path={["footer", "menu", 2, "title"]}
                            />
                        </p>
                        <div className="mt-4">
                            <a
                                target="_blank"
                                className="text-dark text-decoration-none"
                                href="https://www.google.com/maps/place/1132+Kane+Concourse+%23204,+Bay+Harbor+Islands,+FL+33154/@25.8864383,-80.1341726,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9b284b6b2e4ff:0x6a9f67316bb7cc69!8m2!3d25.8864383!4d-80.1319839"
                            >
                                1132 Kane Concourse <br />
                                Suite 204
                                <br /> Bay Harbor Islands, FL 33154
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
