import React, { Component } from "react";
import logo from "../../static/logo-pink.svg";

class ComingSoon extends Component {
    render() {
        return (
            <div className="coming-soon-container">
                <div className="col-sm-12 col-md-5 col-lg-3 d-flex align-items-center justify-content-center flex-column text-center">
                    <img src={logo} />
                    <h3 className="mt-4">Coming soon</h3>
                </div>
            </div>
        );
    }
}

export default ComingSoon;
