import React from "react";
import { Title, Link, Meta } from "react-head";

const Seo = () => (
    <>
        <Title>Jam Real Estate Group</Title>
        <Meta
            name="description"
            content="We strive to help our clients find their dream property."
        />
        <Link rel="stylesheet" href="https://use.typekit.net/gnd3oky.css" />
    </>
);

export default Seo;
